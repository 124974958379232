@import '../../../../../../Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';

.add-to-cart-modal {
  @media screen and (max-width: @screen-md-max) {
    position: fixed;
    top: calc(100% - @spacer9);
    transition: transform ease-in-out 0.3s;
    background-color: white;
    display: flex;
    flex-direction: column;
    min-width: 100vw;
    max-height: calc(100% - @spacer9);
    overflow-y: auto;
    margin-top: @spacer9;

    &.wrapper-visible {
      transform: translate(0, -100%);
    }

    &.on-top {
      z-index: 1200;
    }
  }

  @media screen and (min-width: @screen-lg-min) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 100%;
    transition: transform ease-in-out 0.3s;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 48rem;
    max-height: 100vh;

    &.wrapper-visible {
      transform: translate(-100%, 0);
    }

    &.on-top {
      z-index: 1200;
    }
  }

  .product-detail-modal-webtitle2,
  .accessory-webtitle2 {
    color: @Gray80;
  }

  .add-to-cart-modal-header {
    padding: @spacer6 @spacer6 @spacer3 @spacer6;
    display: flex;
    justify-content: end;

    .hide-add-to-cart-modal {
      padding: 0;
      height: 2.4rem;
      width: 2.4rem;
    }
  }

  .modal-content-section {
    padding: 0 @spacer6 @spacer6 @spacer6;

    @media screen and (min-width: @screen-lg-min) {
      padding: 0 @spacer9 @spacer5 @spacer9;
    }

    overflow: auto !important;

    .add-to-cart-modal-body {
      .products {
        .product {
          align-items: flex-start;

          .image-wrapper {
            background-color: @Gray10;
            margin-right: @spacer4;
            width: 7.6rem;
            height: 7.6rem;

            @media screen and (min-width: @screen-lg-min) {
              width: 10rem;
              height: 10rem;
            }

            img {
              aspect-ratio: 1/1;
              width: 7.6rem;

              @media screen and (min-width: @screen-lg-min) {
                width: 10rem;
              }
            }

            &:has(img.invisible) {
              background-color: @White;
            }
          }

          .product-info {
              flex: 1 1 auto;
          }
        }

        .price {
          margin-left: @spacer6;
        }

        .original-price {
          text-decoration: line-through;
        }

        hr {
          color: @Gray20;
        }
      }

      .buttons {
        a {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .accessories {
        .accessories-list {
          display: flex;
          flex-direction: column;
          row-gap: @spacer6;

          @media screen and (min-width: @screen-lg-min) {
            row-gap: @spacer7;
          }

          .accessory {
            .image-wrapper {
              background-color: @Gray10;
              margin-right: @spacer4;

              img {
                aspect-ratio: 1/1;
                width: 13.5rem;
                min-width: 13.5rem;

                @media screen and (min-width: @screen-lg-min) {
                  width: 20rem;
                  min-width: 20rem;
                }
              }
            }

            .accessory-atc-button {
              display: flex;
              align-items: center;
              column-gap: 0.8rem;
              padding-left: @spacer6;
              padding-right: @spacer3;

              &.success {
                color: @PitchBlack;
                border: 1px solid @PitchBlack;
                background-color: @White;

                i {
                  background-color: @PitchBlack;
                }
              }
            }
          }
        }
      }
    }
  }
}

.add-to-cart-modal-left-slide-in-backdrop {
  display: none;
  z-index: 1050;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;

  &.active {
    display: block;
  }
}
